import React, { createContext, useContext, useState } from "react";
import { Player } from "./types";

export type PlayerContextType = {
    players: Player[];
    updatePlayerInfo: (id: string, newInfo: Player | null) => void;
};
type Props = {
    children?: React.ReactNode;
};
export const PlayerContext = createContext<PlayerContextType | undefined>(
    undefined
);

// Contextを使用するためのカスタムフック
export const usePlayer = () => {
    const context = useContext(PlayerContext);
    if (!context) {
        throw new Error("usePlayer must be used within a PlayerProvider");
    }
    return context;
};

// Contextプロバイダーコンポーネント
export const PlayerProvider: React.FC<Props> = ({ children }) => {
    const [players, setPlayers] = useState<Player[]>([]);

    // プレイヤー情報を更新する関数
    const updatePlayerInfo = (id: string, newInfo: Player | null) => {
        setPlayers((prevPlayers) => {
            const updatedPlayers = [...prevPlayers];
            const existingPlayerIndex = updatedPlayers.findIndex(
                (player) => player.id === id
            );

            if (existingPlayerIndex !== -1) {
                if (newInfo !== null) {
                    // 既存のプレイヤーが見つかった場合、newInfoで更新
                    updatedPlayers[existingPlayerIndex] = {
                        ...updatedPlayers[existingPlayerIndex],
                        ...newInfo,
                    };
                } else {
                    updatedPlayers.splice(existingPlayerIndex, 1);
                }
            } else {
                // 既存のプレイヤーが見つからない場合、新しいプレイヤーを追加
                const newPlayer: Player = {
                    name: "",
                    id: "",
                    cards: [],
                    front_cards: [],
                    ...newInfo,
                };
                updatedPlayers.push(newPlayer);
            }

            return updatedPlayers;
        });
    };

    return (
        <PlayerContext.Provider value={{ players, updatePlayerInfo }}>
            {children}
        </PlayerContext.Provider>
    );
};
