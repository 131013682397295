import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";

interface YesNoDialogProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    then: (b: boolean) => void;
    allowCancel?: boolean;
    title: string;
    message: string;
}

export default function YesNoDialog(props: YesNoDialogProps) {
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleResult = (b: boolean) => {
        props.setOpen(false);
        props.then(b);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={2}>
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleResult(false)}
                    variant="outlined"
                    color="primary">
                    いいえ
                </Button>
                <Button
                    onClick={() => handleResult(true)}
                    variant="contained"
                    color="primary">
                    はい
                </Button>
            </DialogActions>
        </Dialog>
    );
}
