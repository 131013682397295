import { useEffect } from "react";
import "./App.css";
import {
    CssBaseline,
    Snackbar,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import React from "react";
import {
    CardInstance,
    GameStats,
    LogRecord,
    LogType,
    PartialLogRecord,
    Player,
} from "./types";
import PlayerField from "./components/PlayerField";
import { usePlayer } from "./PlayerContext";
import { useMe } from "./MeContext";
import { useSocket } from "./SocketContext";
import GameInfo from "./components/GameInfo";
import YesNoDialog from "./components/YesNoDialog";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

function App() {
    const { socket, updateSocket } = useSocket();
    const { players, updatePlayerInfo } = usePlayer();
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");
    const [logRecords, setLogRecords] = React.useState<LogRecord[]>([]);
    const [disconnectedDialogOpen, setDisconnectedDialogOpen] =
        React.useState(false);
    const [stats, setStats] = React.useState({
        players: 0,
        cards: {
            total: 0,
            stock: 0,
            trash: 0,
        },
        spectators: 0,
    } as GameStats);
    const playersRef = React.useRef(players);

    const { me, updateMe } = useMe();

    const addLog = (record: PartialLogRecord) => {
        setLogRecords((prev) => [
            { ...record, time: new Date().toLocaleTimeString() },
            ...prev,
        ]);
    };
    useEffect(() => {
        playersRef.current = players;
    }, [players]);
    const onMessage = (event: MessageEvent) => {
        let data = JSON.parse(event.data);
        console.log(data);
        if (data.type === "you") {
            let player = data.player as Player;
            updateMe(player);
            addLog({
                type: "Me",
                player: undefined,
                message:
                    "クライアントに割り当てられたプレイヤーの情報を受け取りました。",
            });
        } else if (data.type === "join") {
            let playersData = data.players as Player[];
            // プレイヤー情報をContextを通じて更新
            playersData.forEach((player) => {
                updatePlayerInfo(player.id, player);
            });
            addLog({
                type: LogType.JOIN,
                player: data.player as Player,
                message: data.player.name + "が参加しました。",
            });
        } else if (data.type === "leave") {
            updatePlayerInfo(data.player.id, null);
            addLog({
                type: LogType.LEAVE,
                player: data.player as Player,
                message: data.player.name + "が退出しました。",
            });
        } else if (data.type === "start") {
            // ゲームが開始した場合の処理
            // ...
            addLog({
                type: LogType.START,
                player: undefined,
                message: "ゲームが開始しました。手札が配られました。",
            });
        } else if (data.type === "draw") {
            // カードを引いた場合の処理
            const targetPlayer = playersRef.current.find(
                (p) => p.id === data.player.id
            );
            if (targetPlayer) {
                if (!targetPlayer.cards) {
                    targetPlayer.cards = [];
                }
                targetPlayer.cards.push(data.card as CardInstance);

                // プレイヤー情報をContextを通じて更新
                updatePlayerInfo(data.player.id, targetPlayer);
                addLog({
                    type: LogType.DRAW,
                    player: targetPlayer,
                    message: "カードを引きました。",
                });
            }
        } else if (data.type === "use") {
            if (data.placement_player) {
                updatePlayerInfo(
                    data.placement_player.id,
                    data.placement_player
                );
            }
            addLog({
                type: LogType.USE,
                player: data.player,
                message: "カードを使用しました。",
            });
            updatePlayerInfo(data.player.id, data.player);
        } else if (data.type === "reveal") {
            if (data.card) {
                updatePlayerInfo(
                    data.placement_player.id,
                    data.placement_player
                );
                addLog({
                    type: LogType.REVEAL,
                    player: data.placement_player,
                    message:
                        "カードを公開しました: " + data.card.card_info.name,
                });
            }
        } else if (data.type === "move") {
            updatePlayerInfo(data.player.id, data.player);
            updatePlayerInfo(data.before.id, data.before);
            console.log(me);
            addLog({
                type: LogType.PUT,
                player: data.placement_player,
                message: `カードが${data.before.name}から${data.player.name}の${data.card.placement}に移動しました。`,
            });
        } else if (data.type === "stats") {
            setStats(data as GameStats);
        } else if (data.type === "error") {
            setSnackMessage(data.message);
            setSnackOpen(true);
            addLog({
                type: "Error",
                player: undefined,
                message: "エラーを受信しました: " + data.message,
            });
        } else if (data.type === "sync") {
            let playersData = data.players as Player[];
            playersData.forEach((player) => {
                updatePlayerInfo(player.id, player);
            });
            updateMe(data.me);
            addLog({
                type: "Sync",
                player: undefined,
                message: "情報をサーバーと同期しました。",
            });
        } else if (data.type === "connect") {
            addLog({
                type: "Connect",
                player: undefined,
                message: "クライアントがWebSocketに接続しました。",
            });
        } else if (data.type === "disconnect") {
            addLog({
                type: "Disconnect",
                player: undefined,
                message: "クライアントがWebSocketから切断しました。",
            });
        }
    };

    const handleReconnectOrNot = (reconnect: boolean) => {
        if (reconnect) {
            connect(() => {
                if (me.id !== "0") {
                    socket?.send(
                        JSON.stringify({
                            type: "join",
                            name: me.id,
                        })
                    );
                }
            });
        }
    };

    const connect = (onOpen?: () => void) => {
        const endpoint =
            process.env.NODE_ENV === "development"
                ? "ws://localhost:8000/api/v1/ws"
                : "wss://do-not-debug-api.shieru-lab.com/api/v1/ws";
        let urlParams = new URL(window.location.href).searchParams;
        let password = urlParams.get("password");
        console.log(password);
        if (!password) {
            password = prompt("マッチメイキング パスワード: ");
        }
        if (password) {
            const websocket = new WebSocket(
                endpoint + "/" + encodeURIComponent(password)
            );
            updateSocket(websocket);

            websocket.addEventListener("message", onMessage);
            websocket.addEventListener("close", () =>
                setDisconnectedDialogOpen(true)
            );
            if (onOpen) {
                websocket.addEventListener("open", onOpen);
            }

            return () => {
                websocket.close();
                websocket.removeEventListener("message", onMessage);
            };
        }
    };
    //eslint-disable-next-line
    useEffect(connect, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className="App">
                <GameInfo stats={stats} logs={logRecords} />
                {players
                    .sort((a, b) => {
                        if (a.id === me.id) {
                            return -1; // aがme.idと一致する場合、aを前にする
                        } else if (b.id === me.id) {
                            return 1; // bがme.idと一致する場合、bを前にする
                        } else {
                            return 0; // それ以外の場合、順序を変更しない
                        }
                    })
                    .map((player) => {
                        return <PlayerField key={player.id} player={player} />;
                    })}
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackOpen(false)}
                    message={snackMessage}
                />
                <YesNoDialog
                    open={disconnectedDialogOpen}
                    message="WebSocketから切断されました。再接続しますか？"
                    setOpen={setDisconnectedDialogOpen}
                    then={handleReconnectOrNot}
                    title="切断されました"></YesNoDialog>
            </div>
        </ThemeProvider>
    );
}

export default App;
