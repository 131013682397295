import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Tooltip,
} from "@mui/material";
import { CardInstance, Player } from "../types";
import nl2br from "../utils";
import PlayerChooseDialog from "./PlayerChooseDialog";
import InfoIcon from "@mui/icons-material/Info";
import PlayerGiveDialog from "./PlayerGiveDialog";
import { useMe } from "../MeContext";
import CloseIcon from "@mui/icons-material/Close";
const icon_map = {
    original: "オリジナル",
    star: "オリジナル: スターカード",
    custom: "カスタム",
    "custom-star": "カスタム: スターカード",
    either: "黒",
};

interface GameCardDialogProps {
    canSee: boolean;
    put: (p: Player) => void;
    give: (p: Player) => void;
    trash: () => void;
    reveal: () => void;
    card: CardInstance;
    open: boolean;
    setOpen: (b: boolean) => void;
}

export default function GameCardDialog(props: GameCardDialogProps) {
    const [chooseOpen, setChooseOpen] = useState(false);
    const [moveOpen, setMoveOpen] = useState(false);
    const { me } = useMe();

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleChoose = () => {
        setChooseOpen(true);
    };

    const handleMove = () => {
        setMoveOpen(true);
    };

    const chooseEnter = (player: Player, reveal: boolean) => {
        props.put(player);
        if (reveal) {
            props.reveal();
        }
        props.setOpen(false);
        setChooseOpen(false);
    };

    const moveEnter = (player: Player) => {
        props.give(player);
        props.setOpen(false);
        setMoveOpen(false);
    };

    const amISpectator = React.useMemo(() => me.id === "0", [me]);
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth>
            <DialogTitle id="alert-dialog-title" sx={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>

                    <span>
                        {props.canSee ? props.card.card_info.name : "???"}
                    </span>
                    <span style={{ marginLeft: "auto" }}>
                        <Tooltip
                            title={
                                props.canSee
                                    ? "ソース: " +
                                      icon_map[props.card.card_info.source]
                                    : "ソース: ウスター or オーロラ"
                            }>
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {props.canSee
                        ? nl2br(props.card.card_info.description)
                        : nl2br(
                              "このカードの中身はまだわからない。それがたとえ、折り紙やトランプだったとしても。\nこの画面を1時間ずっと眺めていたら、もしかしたらヒントが見えてくるかもしれない。\nいや、そんなことはないかもしれない。"
                          )}
                </DialogContentText>
                <hr />
                <DialogContentText sx={{ fontSize: 12 }}>
                    {props.canSee
                        ? nl2br(props.card.card_info.tips)
                        : "これがシュレディンガーの猫！"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.reveal();
                        handleClose();
                    }}
                    disabled={
                        !props.canSee ||
                        !props.card.hidden_except_user ||
                        amISpectator
                    }
                    variant="outlined"
                    color="success">
                    カードを全員に公開する
                </Button>
                <Button
                    onClick={handleMove}
                    disabled={!props.canSee || amISpectator}
                    variant="outlined"
                    color="secondary">
                    カードを渡す
                </Button>
                <Button
                    onClick={() => {
                        props.trash();
                        handleClose();
                    }}
                    disabled={!props.canSee || amISpectator}
                    variant="contained"
                    color="error">
                    カードを捨て札に捨てる
                </Button>
                <Button
                    onClick={handleChoose}
                    disabled={!props.canSee || amISpectator}
                    variant="contained"
                    color="primary">
                    カードを場に置く
                </Button>
                <PlayerChooseDialog
                    open={chooseOpen}
                    setOpen={setChooseOpen}
                    enter={chooseEnter}
                />
                <PlayerGiveDialog
                    open={moveOpen}
                    setOpen={setMoveOpen}
                    enter={moveEnter}
                />
            </DialogActions>
        </Dialog>
    );
}
