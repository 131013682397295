import {
    Slide,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LogRecord } from "../types";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface LogDialogProps {
    records: LogRecord[];
}

export default function LogDialog(props: LogDialogProps) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                ゲームログを開く
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div">
                            ログ
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    {props.records.map((record) => (
                        <ListItem divider>
                            <ListItemText
                                primary={
                                    "[" +
                                    record.time +
                                    "] " +
                                    record.type +
                                    ": " +
                                    record.message
                                }
                                secondary={
                                    record.player
                                        ? `${record.player?.name} (#${record.player?.id})`
                                        : ""
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </div>
    );
}
