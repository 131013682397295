// CardSourceとCardPlacementのEnum型
export enum CardSource {
    ORIGINAL = "original",
    STAR = "star",
    CUSTOM = "custom",
    CUSTOM_STAR = "custom-star",
    EITHER = "either",
}

export enum CardPlacement {
    FRONT = "front",
    TRASH = "trash",
    HAND = "hand",
    STOCK = "stock",
}

// CardInfo型
export interface CardInfo {
    name: string;
    description: string;
    tips: string;
    source: CardSource;
    default_placement: CardPlacement;
}

// CardInstance型
export interface CardInstance {
    card_info: CardInfo;
    placement_player?: Player;
    placement: CardPlacement;
    used_by?: Player;
    hidden_except_user: boolean;
    id: string;
}

// Player型 (必要に応じて定義)
export interface Player {
    name: string;
    cards: CardInstance[] | undefined;
    front_cards: CardInstance[] | undefined;
    id: string;
}

export interface GameStats {
    players: number;
    cards: GameStatsCard;
    spectators: number;
}

export interface GameStatsCard {
    total: number;
    stock: number;
    trash: number;
}

export interface LogRecord extends PartialLogRecord {
    time: string;
}
export interface PartialLogRecord {
    message: string;
    player: Player | undefined;
    type: LogType | string;
}

export enum LogType {
    JOIN = "Join",
    LEAVE = "Leave",
    DRAW = "Draw",
    PUT = "Put",
    USE = "Use",
    REVEAL = "Reveal",
    TRASH = "Trash",
    START = "Start",
}
