import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    FormGroup,
    Switch,
    Tooltip,
} from "@mui/material";
import { Player } from "../types";
import { usePlayer } from "../PlayerContext";
import { useMe } from "../MeContext";

interface PlayerChooseDialogProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    enter: (p: Player, reveal: boolean) => void;
}

export default function PlayerChooseDialog(props: PlayerChooseDialogProps) {
    const [player, setPlayer] = useState<number>(0); // 状態をuseStateフックで管理
    const { players } = usePlayer();
    const { me } = useMe();
    const [chooseReveal, setChooseReveal] = useState(false);

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleEnter = () => {
        if (typeof player === "number") {
            const selectedPlayer = players[player];
            props.enter(selectedPlayer, chooseReveal);
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md">
            <DialogTitle id="alert-dialog-title">
                カードを場に設置する
            </DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={2}>
                    どのプレイヤーの前に設置しますか？
                </DialogContentText>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        プレイヤー
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={player}
                        label="プレイヤー"
                        onChange={(e) => setPlayer(e.target.value as number)} // 状態を更新
                    >
                        {players.map((p, i) => (
                            <MenuItem key={i} value={i}>
                                {p.id === me.id ? "あなた" : p.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormGroup>
                        <Tooltip title="後から公開することもできます。">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={chooseReveal}
                                        onChange={() =>
                                            setChooseReveal(!chooseReveal)
                                        }
                                    />
                                }
                                label="カードを全員に公開する"
                            />
                        </Tooltip>
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>閉じる</Button>
                <Button
                    onClick={handleEnter}
                    variant="contained"
                    color="primary"
                    disabled={typeof player !== "number"}>
                    確定する
                </Button>
            </DialogActions>
        </Dialog>
    );
}
