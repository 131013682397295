import React, { createContext, useContext, useState } from "react";

type SocketContextType = {
    socket: WebSocket | undefined;
    updateSocket: (newSocket: WebSocket) => void;
};
type Props = {
    children?: React.ReactNode;
};
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Contextを使用するためのカスタムフック
export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error("useMe must be used within a PlayerProvider");
    }
    return context;
};

// Contextプロバイダーコンポーネント
export const SocketProvider: React.FC<Props> = ({ children }) => {
    const [socket, setSocket] = useState<WebSocket>();

    // プレイヤー情報を更新する関数
    const updateSocket = (newSocket: WebSocket) => {
        setSocket(newSocket);
    };

    return (
        <SocketContext.Provider
            value={{ socket: socket, updateSocket: updateSocket }}>
            {children}
        </SocketContext.Provider>
    );
};
