import React from "react";

export default function nl2br(text: string) {
    var regex = /(\n)/g;
    return text.split(regex).map(function (line) {
        if (line.match(regex)) {
            return React.createElement("br");
        } else {
            return line;
        }
    });
}
