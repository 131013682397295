import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PlayerProvider } from "./PlayerContext";
import { MeProvider } from "./MeContext";
import { SocketProvider } from "./SocketContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <SocketProvider>
        <MeProvider>
            <PlayerProvider>
                <App />
            </PlayerProvider>
        </MeProvider>
    </SocketProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
