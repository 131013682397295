import { Paper, Typography, Grid, Box, Stack } from "@mui/material";
import { Player } from "../types";
import GameCard from "./GameCard";
import { useMe } from "../MeContext";

interface PlayerFieldProps {
    player: Player;
}

const PlayerField: React.FC<PlayerFieldProps> = ({ player }) => {
    const { me } = useMe();
    return (
        <Paper elevation={3} style={{ padding: "16px", marginBottom: "16px" }}>
            <Typography variant="h6" gutterBottom>
                {player.id === me.id ? "あなた" : player.name}のフィールド
            </Typography>
            <Grid container spacing={2}>
                <Grid xs={6} item>
                    {/* プレイヤーの手札 */}
                    <Box>
                        <Typography variant="subtitle1">手札</Typography>
                        <Stack
                            direction={"row"}
                            spacing={0}
                            useFlexGap
                            flexWrap="wrap"
                            justifyContent="center">
                            {player.cards?.map((card) => (
                                <GameCard key={card.id} cardInstance={card} />
                            ))}
                        </Stack>
                    </Box>
                </Grid>
                <Grid xs={6} item>
                    {/* プレイヤーの場札 */}
                    <Box>
                        <Typography variant="subtitle1">場札</Typography>
                        <Stack
                            direction={"row"}
                            spacing={0}
                            useFlexGap
                            flexWrap="wrap"
                            justifyContent="center">
                            {player.front_cards?.map((card) => (
                                <GameCard key={card.id} cardInstance={card} />
                            ))}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PlayerField;
