import React, { createContext, useContext, useState } from "react";
import { Player } from "./types";

type MeContextType = {
    me: Player;
    updateMe: (newMe: Player) => void;
};
type Props = {
    children?: React.ReactNode;
};
const MeContext = createContext<MeContextType | undefined>(undefined);

// Contextを使用するためのカスタムフック
export const useMe = () => {
    const context = useContext(MeContext);
    if (!context) {
        throw new Error("useMe must be used within a PlayerProvider");
    }
    return context;
};

// Contextプロバイダーコンポーネント
export const MeProvider: React.FC<Props> = ({ children }) => {
    const [me, setMe] = useState<Player>({
        name: "",
        id: "0",
    } as Player);

    // プレイヤー情報を更新する関数
    const updateMe = (newMe: Player) => {
        setMe(newMe);
    };

    return (
        <MeContext.Provider value={{ me: me, updateMe: updateMe }}>
            {children}
        </MeContext.Provider>
    );
};
