import { Button, Typography } from "@mui/material";
import React from "react";
import { GameStats, LogRecord } from "../types";
import { useMe } from "../MeContext";
import { useSocket } from "../SocketContext";
import LogDialog from "./LogDialog";

interface GameCardProps {
    stats: GameStats;
    logs: LogRecord[];
}
// Cardコンポーネント
const GameCard: React.FC<GameCardProps> = (props) => {
    const { me } = useMe();
    const { socket } = useSocket();

    console.log(me);
    const draw = () => {
        socket?.send(JSON.stringify({ type: "draw" }));
    };

    const join = () => {
        let randomInt = Math.floor(Math.random() * 100) + 1;
        let name = prompt("ゲームに使用する名前: ");
        if (!name) {
            name = "Player " + randomInt;
        }
        socket?.send(
            JSON.stringify({
                type: "join",
                name: name,
            })
        );
    };

    const sync = () => {
        socket?.send(JSON.stringify({ type: "sync" }));
    };
    return (
        <>
            <Typography variant="h4" margin={2}>
                デバッグなんてしてないよ - We Didn't Debug This At All!
            </Typography>
            <hr />
            <Typography>
                {props.stats.players} players / {props.stats.spectators}{" "}
                spectators <br />
                <b>Card Stats: </b>
                {props.stats.cards.stock} in stock / {props.stats.cards.trash}{" "}
                trashed / {props.stats.cards.total} for total
            </Typography>
            <Typography>
                {me.id === "0" ? (
                    "あなたはスペクテイターです！"
                ) : (
                    <>
                        あなた: {me.name} (
                        <code color="secondary">#{me.id}</code>)
                    </>
                )}
            </Typography>
            <Button onClick={draw}>カードを引く</Button>
            <Button onClick={join} disabled={me.id !== "0"}>
                参加する
            </Button>
            <Button onClick={sync}>同期する</Button>
            <LogDialog records={props.logs} />
        </>
    );
};

export default GameCard;
