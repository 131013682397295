import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { CardInstance, CardPlacement, Player } from "../types";
import { useMe } from "../MeContext";
import { useSocket } from "../SocketContext";
import GameCardDialog from "./GameCardDialog";
// CardコンポーネントのProps型
export interface CardProps {
    cardInstance: CardInstance;
    // 他に必要なプロパティがあればここに追加
}
// Cardコンポーネント
const GameCard: React.FC<CardProps> = ({ cardInstance }) => {
    const { me } = useMe();
    const { socket } = useSocket();
    const [open, setOpen] = React.useState(false);

    const canSee = React.useMemo(() => {
        if (me.id === "0") {
            return true;
        }
        console.log(cardInstance.placement_player!.id, me.id, cardInstance);
        if (cardInstance.placement === CardPlacement.FRONT) {
            if (
                cardInstance.hidden_except_user &&
                cardInstance.used_by!.id !== me.id
            ) {
                return false;
            }
        } else {
            if (
                cardInstance.hidden_except_user &&
                cardInstance.placement_player!.id !== me.id
            ) {
                return false;
            }
        }
        return true;
    }, [cardInstance, me]);

    const border = React.useMemo(() => {
        if (cardInstance.hidden_except_user) {
            if (me.id === "0") {
                return "1px dashed lightblue";
            } else {
                return "";
            }
        } else {
            return "1px solid lightgreen";
        }
    }, [cardInstance, me]);

    const putCard = (p: Player) => {
        socket?.send(
            JSON.stringify({
                type: "move",
                card: cardInstance.id,
                placement: "FRONT",
                player: p.id,
            })
        );
    };

    const trashCard = () => {
        socket?.send(
            JSON.stringify({
                type: "use",
                card: cardInstance.id,
                placement: "TRASH",
                player: me.id,
            })
        );
    };

    const revealCard = () => {
        socket?.send(
            JSON.stringify({
                type: "reveal",
                card: cardInstance.id,
            })
        );
    };

    const giveCard = (p: Player) => {
        socket?.send(
            JSON.stringify({
                type: "move",
                card: cardInstance.id,
                player: p.id,
                placement: "HAND",
            })
        );
    };

    const openDialog = () => {
        setOpen(true);
    };
    return (
        <Box sx={{}}>
            <Card
                sx={{
                    width: 340,
                    margin: 1,
                    height: 100,
                    border: border,
                }}>
                <CardContent>
                    <Typography variant="h5">
                        {canSee
                            ? cardInstance.card_info.source.endsWith("star")
                                ? "★"
                                : ""
                            : ""}
                        {canSee ? cardInstance.card_info.name : "???"}
                    </Typography>
                    <Button color="primary" onClick={openDialog} size="large">
                        詳細
                    </Button>
                    <GameCardDialog
                        canSee={canSee}
                        card={cardInstance}
                        put={putCard}
                        trash={trashCard}
                        reveal={revealCard}
                        give={giveCard}
                        open={open}
                        setOpen={setOpen}
                    />
                </CardContent>
            </Card>
        </Box>
    );
};

export default GameCard;
